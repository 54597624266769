import React, { useState, useMemo, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import {
  refreshWalletPublicKeysV2,
  useBalanceInfo,
  useWallet,
  useWalletPublicKeysV2,
  useWalletSelector,
} from '../utils/wallet';
import { findAssociatedTokenAddress } from '../utils/tokens';
import LoadingIndicator from './LoadingIndicator';
import Collapse from '@material-ui/core/Collapse';
import {
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { abbreviateAddress, useIsExtensionWidth } from '../utils/utils';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import ReceiveIcon from '@material-ui/icons/WorkOutline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import AddTokenDialog from './AddTokenDialog';
import ExportAccountDialog from './ExportAccountDialog';
import SendDialog from './SendDialog';
import DepositDialog from './DepositDialog';
import { useConnection, useSolanaExplorerUrlSuffix } from '../utils/connection';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { shortenAddress } from '../utils/utils';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TokenIcon from './TokenIcon';
import EditAccountNameDialog from './EditAccountNameDialog';
import MergeAccountsDialog from './MergeAccountsDialog';
import { useTokenInfos } from '../utils/tokens/names';
import _ from 'lodash';

const balanceFormat = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 6,
  useGrouping: true,
});

const associatedTokensCache = {};

const numberFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default function BalancesList() {
  const wallet = useWallet();
  const [tokenAccountsInfo, loaded] = useWalletPublicKeysV2();
  const [hideZeroBalance, setHideZeroBalance] = useState(true);
  const tokenInfos = useTokenInfos();
  const [showAddTokenDialog, setShowAddTokenDialog] = useState(false);
  const [showEditAccountNameDialog, setShowEditAccountNameDialog] = useState(
    false,
  );
  const [priceInfos, setPriceInfos] = useState(null);
  const [totalUsdValue, setTotalUsdValue] = useState(0.0);
  const [sortedTokenInfos, setSortedTokenInfos] = useState([]);
  const [showMergeAccounts, setShowMergeAccounts] = useState(false);
  const { accounts, setAccountName } = useWalletSelector();
  const [isCopied, setIsCopied] = useState(false);
  const isExtensionWidth = useIsExtensionWidth();
  const selectedAccount = accounts.find((a) => a.isSelected);
  const allTokensLoaded = loaded;
  const filteredTokenInfos = useMemo(() => {
    if (tokenAccountsInfo && loaded && tokenInfos) {
      let filteredTokenInfoList;
      filteredTokenInfoList = tokenAccountsInfo.map((account) => {
        const findToken = tokenInfos.find((tokenInfo) => {
          return tokenInfo.address === account.parsed.mint.toBase58();
        });
        return {
          ...account,
          tokenInfo: { ...findToken },
        };
      });
      if (hideZeroBalance) {
        filteredTokenInfoList = filteredTokenInfoList.filter(
          (filteredTokenInfo) => {
            return parseInt(filteredTokenInfo.parsed.amount.toString()) > 0;
          },
        );
      }
      return filteredTokenInfoList;
    } else {
      return [];
    }
  }, [tokenAccountsInfo, hideZeroBalance, loaded, tokenInfos]);

  useEffect(() => {
    const fetchTokensPrice = async (ids) => {
      const response = await fetch(`https://price.jup.ag/v4/price?ids=${ids}`);
      if (response.ok) {
        const responseJson = await response.json();
        return responseJson
          ? setPriceInfos(responseJson.data)
          : setPriceInfos(null);
      }
      return setPriceInfos(null);
    };

    if (filteredTokenInfos && filteredTokenInfos.length > 0) {
      const tokenMints = filteredTokenInfos
        .map((item) => {
          return item.tokenInfo.symbol
            ? item.parsed.mint.toString()
            : undefined;
        })
        .filter((elem) => elem);
      if (tokenMints && tokenMints.length > 0) {
        fetchTokensPrice(tokenMints.join(',')).catch(() =>
          console.log('fetch price error'),
        );
      }
    }
  }, [filteredTokenInfos]);
  const solanaFirstSort = (o) => {
    return o.tokenInfo.symbol === 'SOL' ? 1 : 2;
  };
  const netWorthSort = (o) => {
    if (priceInfos) {
      const tokenPrice = priceInfos[o.parsed.mint.toString()];
      return (
        tokenPrice?.price *
        (o.parsed.amount / Math.pow(10, o.tokenInfo.decimals ?? 0)) *
        -1
      );
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (filteredTokenInfos && filteredTokenInfos.length > 0 && priceInfos) {
      const totalPrice = filteredTokenInfos
        .map((token) => {
          const tokenPrice = priceInfos[token.parsed.mint.toString()];
          return (
            tokenPrice?.price *
            (token.parsed.amount / Math.pow(10, token.tokenInfo.decimals ?? 0))
          );
        })
        .filter((elem) => !isNaN(elem))
        .reduce((a, b) => a + b, 0.0);
      setTotalUsdValue(totalPrice);
      setSortedTokenInfos(
        _.sortBy(filteredTokenInfos, [solanaFirstSort, netWorthSort]),
      );
    } else {
      setTotalUsdValue(0.0);
      setSortedTokenInfos(filteredTokenInfos);
    }
  }, [filteredTokenInfos, priceInfos]);

  const balanceListItemsMemo = useMemo(() => {
    return sortedTokenInfos.map((accountInfo) => {
      return React.memo(() => {
        return (
          <BalanceListItemV2
            key={accountInfo.publicKey.toString()}
            publicKey={accountInfo.publicKey}
            accountInfo={accountInfo}
            priceInfo={
              priceInfos
                ? priceInfos[accountInfo.parsed.mint.toString()]
                : undefined
            }
          />
        );
      });
    });
  }, [sortedTokenInfos, priceInfos]);

  const iconSize = isExtensionWidth ? 'small' : 'medium';

  const handleSwitch = () => {
    setHideZeroBalance((prev) => !prev);
  };

  return (
    <Paper>
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <CopyToClipboard
            text={selectedAccount && selectedAccount.address.toBase58()}
            onCopy={() => {
              setIsCopied(true);
              setTimeout(() => {
                setIsCopied(false);
              }, 1000);
            }}
          >
            <Tooltip
              title={
                <Typography>
                  {isCopied ? 'Copied' : 'Copy to clipboard'}
                </Typography>
              }
              style={{ fontSize: '10rem' }}
            >
              <Typography
                variant="h6"
                style={{
                  flexGrow: 1,
                  fontSize: isExtensionWidth && '1rem',
                  cursor: 'pointer',
                }}
                component="h2"
              >
                {selectedAccount && selectedAccount.name}
                {isExtensionWidth
                  ? ''
                  : ` (${
                      selectedAccount &&
                      shortenAddress(selectedAccount.address.toBase58())
                    })`}{' '}
                {allTokensLoaded && (
                  <>
                    ({numberFormat.format(parseInt(totalUsdValue.toFixed(2)))})
                  </>
                )}
              </Typography>
            </Tooltip>
          </CopyToClipboard>
          <Tooltip title="Hide zero balance" arrow>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={hideZeroBalance}
                    onChange={handleSwitch}
                    color={'primary'}
                  />
                }
                label="Hide zero balance"
              />
            </FormGroup>
          </Tooltip>
          {selectedAccount &&
            selectedAccount.name !== 'Main account' &&
            selectedAccount.name !== 'Hardware wallet' && (
              <Tooltip title="Edit Account Name" arrow>
                <IconButton
                  size={iconSize}
                  onClick={() => setShowEditAccountNameDialog(true)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
          <Tooltip title="Add Token" arrow>
            <IconButton
              size={iconSize}
              onClick={() => setShowAddTokenDialog(true)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Refresh" arrow>
            <IconButton
              size={iconSize}
              onClick={() => {
                refreshWalletPublicKeysV2(wallet);
              }}
              style={{ marginRight: -12 }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <List disablePadding>
        {balanceListItemsMemo.map((Memoized, idx) => (
          <Memoized key={idx} />
        ))}
        {loaded ? null : <LoadingIndicator />}
      </List>
      <AddTokenDialog
        open={showAddTokenDialog}
        onClose={() => setShowAddTokenDialog(false)}
      />
      <EditAccountNameDialog
        open={showEditAccountNameDialog}
        onClose={() => setShowEditAccountNameDialog(false)}
        oldName={selectedAccount ? selectedAccount.name : ''}
        onEdit={(name) => {
          setAccountName(selectedAccount.selector, name);
          setShowEditAccountNameDialog(false);
        }}
      />
      <MergeAccountsDialog
        open={showMergeAccounts}
        onClose={() => setShowMergeAccounts(false)}
      />
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  address: {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
  },
  itemDetails: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  viewDetails: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export function BalanceListItem({ publicKey, expandable, setUsdValue }) {
  const wallet = useWallet();
  const balanceInfo = useBalanceInfo(publicKey);
  const classes = useStyles();
  const connection = useConnection();
  const [open, setOpen] = useState(false);
  const isExtensionWidth = useIsExtensionWidth();
  const [, setForceUpdate] = useState(false);
  // Valid states:
  //   * undefined => loading.
  //   * null => not found.
  //   * else => price is loaded.
  const [price, setPrice] = useState(undefined);
  useEffect(() => {
    if (balanceInfo) {
      if (balanceInfo.tokenSymbol) {
        const coin = balanceInfo.tokenSymbol.toUpperCase();
        // Don't fetch USD stable coins. Mark to 1 USD.
        if (coin === 'USDT' || coin === 'USDC') {
          setPrice(1);
        } else {
          setPrice(null);
        }
      }
      // No token symbol so don't fetch market data.
      else {
        setPrice(null);
      }
    }
  }, [price, balanceInfo, connection]);

  expandable = expandable === undefined ? true : expandable;

  if (!balanceInfo) {
    return <LoadingIndicator delay={0} />;
  }

  let {
    amount,
    decimals,
    mint,
    tokenName,
    tokenSymbol,
    tokenLogoUri,
  } = balanceInfo;
  tokenName = tokenName ?? abbreviateAddress(mint);
  let displayName;
  if (isExtensionWidth) {
    displayName = tokenSymbol ?? tokenName;
  } else {
    displayName = tokenName + (tokenSymbol ? ` (${tokenSymbol})` : '');
  }

  // Fetch and cache the associated token address.
  if (wallet && wallet.publicKey && mint) {
    if (
      associatedTokensCache[wallet.publicKey.toString()] === undefined ||
      associatedTokensCache[wallet.publicKey.toString()][mint.toString()] ===
        undefined
    ) {
      findAssociatedTokenAddress(wallet.publicKey, mint).then((assocTok) => {
        let walletAccounts = Object.assign(
          {},
          associatedTokensCache[wallet.publicKey.toString()],
        );
        walletAccounts[mint.toString()] = assocTok;
        associatedTokensCache[wallet.publicKey.toString()] = walletAccounts;
        if (assocTok.equals(publicKey)) {
          // Force a rerender now that we've cached the value.
          setForceUpdate((forceUpdate) => !forceUpdate);
        }
      });
    }
  }

  // undefined => not loaded.
  let isAssociatedToken = mint ? undefined : false;
  if (
    wallet &&
    wallet.publicKey &&
    mint &&
    associatedTokensCache[wallet.publicKey.toString()]
  ) {
    let acc =
      associatedTokensCache[wallet.publicKey.toString()][mint.toString()];
    if (acc) {
      isAssociatedToken = !!acc.equals(publicKey);
    }
  }

  const subtitle =
    isExtensionWidth || !publicKey.equals(balanceInfo.owner) ? undefined : (
      <div style={{ display: 'flex', height: '20px', overflow: 'hidden' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          {publicKey.toBase58()}
        </div>
      </div>
    );

  const usdValue =
    price === undefined // Not yet loaded.
      ? undefined
      : price === null // Loaded and empty.
      ? null
      : ((amount / Math.pow(10, decimals)) * price).toFixed(2); // Loaded.
  if (setUsdValue && usdValue !== undefined) {
    setUsdValue(publicKey, usdValue === null ? null : parseFloat(usdValue));
  }

  return (
    <>
      <ListItem button onClick={() => expandable && setOpen((open) => !open)}>
        <ListItemIcon>
          <TokenIcon
            mint={mint}
            tokenName={tokenName}
            url={tokenLogoUri}
            size={28}
          />
        </ListItemIcon>
        <div style={{ display: 'flex', flex: 1 }}>
          <ListItemText
            primary={
              <>
                {balanceFormat.format(amount / Math.pow(10, decimals))}{' '}
                {displayName}
              </>
            }
            secondary={subtitle}
            secondaryTypographyProps={{ className: classes.address }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {price && (
              <Typography color="textSecondary">
                {numberFormat.format(usdValue)}
              </Typography>
            )}
          </div>
        </div>
        {expandable ? open ? <ExpandLess /> : <ExpandMore /> : <></>}
      </ListItem>
      {expandable && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <BalanceListItemDetails
            isAssociatedToken={isAssociatedToken}
            publicKey={publicKey}
            balanceInfo={balanceInfo}
          />
        </Collapse>
      )}
    </>
  );
}

export function BalanceListItemV2({
  publicKey,
  accountInfo,
  priceInfo,
  expandable,
}) {
  const wallet = useWallet();
  const [open, setOpen] = useState(false);
  const [, setForceUpdate] = useState(false);

  expandable = expandable === undefined ? true : expandable;

  let { amount, mint } = accountInfo?.parsed;
  let {
    symbol: tokenSymbol,
    logoURI: tokenLogoUri,
    decimals,
  } = accountInfo?.tokenInfo;
  const tokenName = tokenSymbol ?? abbreviateAddress(mint);

  // Fetch and cache the associated token address.
  if (wallet && wallet.publicKey && mint) {
    if (
      associatedTokensCache[wallet.publicKey.toString()] === undefined ||
      associatedTokensCache[wallet.publicKey.toString()][mint.toString()] ===
        undefined
    ) {
      findAssociatedTokenAddress(wallet.publicKey, mint).then((assocTok) => {
        let walletAccounts = Object.assign(
          {},
          associatedTokensCache[wallet.publicKey.toString()],
        );
        walletAccounts[mint.toString()] = assocTok;
        associatedTokensCache[wallet.publicKey.toString()] = walletAccounts;
        if (assocTok.equals(publicKey)) {
          // Force a rerender now that we've cached the value.
          setForceUpdate((forceUpdate) => !forceUpdate);
        }
      });
    }
  }

  // undefined => not loaded.
  let isAssociatedToken = mint ? undefined : false;
  if (
    wallet &&
    wallet.publicKey &&
    mint &&
    associatedTokensCache[wallet.publicKey.toString()]
  ) {
    let acc =
      associatedTokensCache[wallet.publicKey.toString()][mint.toString()];
    if (acc) {
      isAssociatedToken = !!acc.equals(publicKey);
    }
  }

  const usdValue =
    priceInfo === undefined
      ? undefined
      : ((amount / Math.pow(10, decimals ?? 0)) * priceInfo?.price).toFixed(2);

  return (
    <>
      <ListItem button onClick={() => expandable && setOpen((open) => !open)}>
        <ListItemIcon>
          <TokenIcon
            mint={mint}
            tokenName={tokenName}
            url={tokenLogoUri}
            size={28}
          />
        </ListItemIcon>
        <div style={{ display: 'flex', flex: 1 }}>
          <ListItemText
            primary={
              <>
                {balanceFormat.format(
                  amount / Math.pow(10, accountInfo.tokenInfo?.decimals ?? 0),
                )}{' '}
                {tokenName}
              </>
            }
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {priceInfo && (
              <Typography color="textSecondary">
                {numberFormat.format(parseInt(usdValue))}
              </Typography>
            )}
          </div>
        </div>
        {expandable ? open ? <ExpandLess /> : <ExpandMore /> : <></>}
      </ListItem>
      {expandable && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <BalanceListItemDetails
            isAssociatedToken={isAssociatedToken}
            publicKey={publicKey}
            accountInfo={accountInfo}
          />
        </Collapse>
      )}
    </>
  );
}

function BalanceListItemDetails({ publicKey, accountInfo, isAssociatedToken }) {
  const urlSuffix = useSolanaExplorerUrlSuffix();
  const classes = useStyles();
  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  const [depositDialogOpen, setDepositDialogOpen] = useState(false);
  const [exportAccDialogOpen, setExportAccDialogOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const wallet = useWallet();
  const isExtensionWidth = useIsExtensionWidth();

  if (!accountInfo) {
    return <LoadingIndicator delay={0} />;
  }

  let { name: tokenName, symbol: tokenSymbol } = accountInfo.tokenInfo;
  let { mint, owner } = accountInfo.parsed;

  // Only show the export UI for the native SOL coin.
  const exportNeedsDisplay =
    (mint === null ||
      mint.toBase58() === 'So11111111111111111111111111111111111111112') &&
    (tokenName === 'SOL' || tokenName === 'Wrapped SOL') &&
    tokenSymbol === 'SOL';

  const isSolAddress = publicKey.equals(owner);
  const additionalInfo = isExtensionWidth ? undefined : (
    <>
      <Typography variant="body2">
        Token Name:{' '}
        {tokenName === 'Wrapped SOL' ? 'SOL' : tokenName ?? 'Unknown'}
      </Typography>
      <Typography variant="body2">
        Token Symbol: {tokenSymbol ?? 'Unknown'}
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {!isSolAddress && isAssociatedToken === false && (
            <div style={{ display: 'flex' }}>
              This is an auxiliary token account.
            </div>
          )}
          <Typography variant="body2">
            <Link
              href={
                `https://solscan.io/account/${publicKey.toBase58()}` + urlSuffix
              }
              target="_blank"
              rel="noopener"
            >
              View on Solscan
            </Link>
          </Typography>
          {!isSolAddress && (
            <Typography variant="body2">
              <Link
                className={classes.viewDetails}
                onClick={() => setShowDetails(!showDetails)}
              >
                View Details
              </Link>
            </Typography>
          )}
          {showDetails &&
            (mint ? (
              <Typography variant="body2" className={classes.address}>
                Mint Address: {mint.toBase58()}
              </Typography>
            ) : null)}
          {!isSolAddress && showDetails && (
            <Typography variant="body2" className={classes.address}>
              {isAssociatedToken ? 'Associated' : ''} Token Metadata:{' '}
              {publicKey.toBase58()}
            </Typography>
          )}
        </div>
        {exportNeedsDisplay && wallet.allowsExport && (
          <div>
            <Typography variant="body2">
              <Link href={'#'} onClick={() => setExportAccDialogOpen(true)}>
                Export
              </Link>
            </Typography>
          </div>
        )}
      </div>
    </>
  );

  return (
    <>
      {wallet.allowsExport && (
        <ExportAccountDialog
          onClose={() => setExportAccDialogOpen(false)}
          open={exportAccDialogOpen}
        />
      )}
      <div className={classes.itemDetails}>
        <div className={classes.buttonContainer}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ReceiveIcon />}
            onClick={() => setDepositDialogOpen(true)}
          >
            Receive
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<SendIcon />}
            onClick={() => setSendDialogOpen(true)}
          >
            Send
          </Button>
        </div>
        {additionalInfo}
      </div>
      <SendDialog
        open={sendDialogOpen}
        onClose={() => setSendDialogOpen(false)}
        accountInfo={accountInfo}
        publicKey={publicKey}
      />
      <DepositDialog
        open={depositDialogOpen}
        onClose={() => setDepositDialogOpen(false)}
        accountInfo={accountInfo}
        publicKey={publicKey}
        isAssociatedToken={isAssociatedToken}
      />
    </>
  );
}
