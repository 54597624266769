import { clusterApiUrl } from '@solana/web3.js';

export const DEFAULT_RPC = 'https://region-03.dexlab.space/v1/rpc'
export const CLUSTERS = [
  {
    name: 'mainnet-beta',
    apiUrl: 'https://region-03.dexlab.space/v1/rpc',
    label: 'Quick Node Mainnet',
    clusterSlug: 'mainnet-beta',
  },
  {
    name: 'devnet',
    apiUrl: clusterApiUrl('devnet'),
    label: 'Devnet',
    clusterSlug: 'devnet',
  },
  {
    name: 'testnet',
    apiUrl: clusterApiUrl('testnet'),
    label: 'Testnet',
    clusterSlug: 'testnet',
  },
  // {
  //   name: 'localnet',
  //   apiUrl: 'http://localhost:8899',
  //   label: 'LocalNet',
  //   clusterSlug: 'localnet',
  // },
];

export function clusterForEndpoint(endpoint) {
  return getClusters().find(({ apiUrl }) => apiUrl === endpoint);
}

const customClusterConfigKey = 'customClusterConfig';

export function addCustomCluster(name, apiUrl) {
  const stringifiedConfig = JSON.stringify({
    name: name,
    label: name,
    apiUrl: apiUrl,
    clusterSlug: null,
  });
  localStorage.setItem(customClusterConfigKey, stringifiedConfig);
}

export function customClusterExists() {
  return !!localStorage.getItem(customClusterConfigKey);
}

export function getClusters() {
  const stringifiedConfig = localStorage.getItem(customClusterConfigKey);
  const config = stringifiedConfig ? JSON.parse(stringifiedConfig) : null;
  return config ? [...CLUSTERS, config] : CLUSTERS;
}
