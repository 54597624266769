import React, { useContext, useState, useEffect } from 'react';
import EventEmitter from 'events';
import { useConnectionConfig } from '../connection';
import { useListener } from '../utils';
import { clusterForEndpoint, DEFAULT_RPC } from '../clusters';
import { useCallback } from 'react';
import { PublicKey } from '@solana/web3.js';
import { TokenListProvider } from '@solana/spl-token-registry';

const POPULAR_TOKENS = {
  [DEFAULT_RPC]: [],
};

const TokenListContext = React.createContext({});

export const KRX_MINT = new PublicKey(
  '8eHWG8x8bN6LyA7mri4zqmW4a8ZdPW26KnCcGwH855NL',
);
export const SOL_MINT = new PublicKey(
  'Ejmc1UB4EsES5oAaRN63SpoxMJidt3ZGBrqrZk49vjTZ',
);
export const WRAPPED_SOL_MINT = new PublicKey(
  'So11111111111111111111111111111111111111112',
);

export const KRX_TOKEN_INFO = {
  chainId: 101,
  address: KRX_MINT.toBase58(),
  name: 'DexlabKRW',
  decimals: 9,
  symbol: 'KRX',
  logoURI: 'https://i.imgur.com/DV6HXPv.png',
  tags: [],
  extensions: {
    website: 'https://www.dexlab.space',
    serumV3Usdc: '3kszj49G9nsdYA6kzjgB7kR8bw9FU5mENj7HAvT8pBci',
  },
};

export const SOL_TOKEN_INFO = {
  chainId: 101,
  address: WRAPPED_SOL_MINT.toBase58(),
  name: 'Solana',
  decimals: 9,
  symbol: 'SOL',
  logoURI:
    'https://cdn.jsdelivr.net/gh/trustwallet/assets@master/blockchains/solana/info/logo.png',
  tags: [],
  extensions: {
    website: 'https://solana.com/',
    serumV3Usdc: '9wFFyRfZBsuAha4YcuxcXLKwMxJR43S7fPfQLusDBzvT',
    serumV3Usdt: 'HWHvQhFmJB3NUcu1aihKmrKegfVxBEHzwVX6yZCKEsi1',
    coingeckoId: 'solana',
    waterfallbot: 'https://t.me/SOLwaterfall',
  },
};

export const RLB_TOKEN_INFO = {
  chainId: 101,
  address: 'RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a',
  name: 'Rollbit Coin',
  decimals: 2,
  symbol: 'RLB',
  logoURI:
    'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a/logo.png',
  tags: [],
  extensions: {
    website: 'https://rollbit.com/',
    coingeckoId: 'rollbit-coin',
  },
};

export function useTokenInfos() {
  const { tokenInfos } = useContext(TokenListContext);
  return tokenInfos;
}

export function TokenRegistryProvider(props) {
  const { endpoint } = useConnectionConfig();
  const [tokenInfos, setTokenInfos] = useState(null);
  useEffect(() => {
    const fetchTokenList = async () => {
      const response = await fetch('https://token.jup.ag/all');
      if (response.ok) {
        const responseJson = await response.json();
        return responseJson ? setTokenInfos(responseJson) : setTokenInfos(null);
      }
      return setTokenInfos(null);
    };
    if (endpoint !== DEFAULT_RPC) return;
    fetchTokenList().catch(() => console.log('error fetch token list.'));
    // console.log(tokenInfos)
    // const tokenListProvider = new TokenListProvider();
    // tokenListProvider.resolve().then((tokenListContainer) => {
    //   const cluster = clusterForEndpoint(endpoint);
    //
    //   const filteredTokenListContainer = tokenListContainer?.filterByClusterSlug(
    //     cluster?.clusterSlug,
    //   );
    //   const tokenInfos =
    //     tokenListContainer !== filteredTokenListContainer
    //       ? filteredTokenListContainer?.getList()
    //       : null; // Workaround for filter return all on unknown slug
    //
    //   const filterTokens = tokenInfos.filter((f) => f.symbol === 'DXL' || f.symbol === 'USDC' || f.symbol === 'KRX' || f.symbol === 'RLB')
    //   filterTokens.push(KRX_TOKEN_INFO)
    //   filterTokens.push(SOL_TOKEN_INFO)
    //   filterTokens.push(RLB_TOKEN_INFO)
    //   setTokenInfos(filterTokens);
    // });
  }, [endpoint]);

  return (
    <TokenListContext.Provider value={{ tokenInfos }}>
      {props.children}
    </TokenListContext.Provider>
  );
}

const customTokenNamesByNetwork = JSON.parse(
  localStorage.getItem('tokenNames') ?? '{}',
);

const nameUpdated = new EventEmitter();
nameUpdated.setMaxListeners(100);

export function useTokenInfo(mint) {
  const { endpoint } = useConnectionConfig();
  useListener(nameUpdated, 'update');
  const tokenInfos = useTokenInfos();
  return getTokenInfo(mint, endpoint, tokenInfos);
}

export function getTokenInfo(mint, endpoint, tokenInfos) {
  if (!mint) {
    return { name: null, symbol: null };
  }

  let info = customTokenNamesByNetwork?.[endpoint]?.[mint.toBase58()];
  let match = tokenInfos?.find(
    (tokenInfo) => tokenInfo.address === mint.toBase58(),
  );

  if (match) {
    if (!info) {
      info = { ...match, logoUri: match.logoURI };
    }
    // The user has overridden a name locally.
    else {
      info = { ...match, ...info, logoUri: match.logoURI };
    }
  }
  return { ...info };
}

export function useUpdateTokenName() {
  const { endpoint } = useConnectionConfig();
  return useCallback(
    function updateTokenName(mint, name, symbol) {
      if (!name || !symbol) {
        if (name) {
          symbol = name;
        } else if (symbol) {
          name = symbol;
        } else {
          return;
        }
      }
      if (!customTokenNamesByNetwork[endpoint]) {
        customTokenNamesByNetwork[endpoint] = {};
      }
      customTokenNamesByNetwork[endpoint][mint.toBase58()] = { name, symbol };
      localStorage.setItem(
        'tokenNames',
        JSON.stringify(customTokenNamesByNetwork),
      );
      nameUpdated.emit('update');
    },
    [endpoint],
  );
}
// Returns tokenInfos for the popular tokens list.
export function usePopularTokens() {
  const tokenInfos = useTokenInfos();
  const { endpoint } = useConnectionConfig();
  return (!POPULAR_TOKENS[endpoint]
    ? []
    : POPULAR_TOKENS[endpoint]
  ).map((tok) =>
    getTokenInfo(new PublicKey(tok.mintAddress), endpoint, tokenInfos),
  );
}
