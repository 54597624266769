import React, { useEffect, useRef, useState } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogForm from './DialogForm';
import { useWallet } from '../utils/wallet';
import { PublicKey } from '@solana/web3.js';
import { abbreviateAddress } from '../utils/utils';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useSendTransaction } from '../utils/notifications';
import {
  TOKEN_PROGRAM_ID,
  WRAPPED_SOL_MINT,
} from '../utils/tokens/instructions';
import { parseTokenAccountData } from '../utils/tokens/data';
import { Switch } from '@material-ui/core';
import {
  resolveDomainName,
  resolveTwitterHandle,
  getNameKey,
} from '../utils/name-service';
import { useConnection } from '../utils/connection';

export default function SendDialog({ open, onClose, publicKey, accountInfo }) {
  const onSubmitRef = useRef();

  const { mint } = accountInfo.parsed;
  const { name: tokenName, symbol: tokenSymbol } = accountInfo.tokenInfo;

  return (
    <>
      <DialogForm
        open={open}
        onClose={onClose}
        onSubmit={() => onSubmitRef.current()}
        fullWidth
      >
        <DialogTitle>
          Send {tokenName ?? abbreviateAddress(mint)}
          {tokenSymbol ? ` (${tokenSymbol})` : null}
        </DialogTitle>
        <SendSplDialog
          onClose={onClose}
          publicKey={publicKey}
          accountInfo={accountInfo}
          onSubmitRef={onSubmitRef}
        />
      </DialogForm>
    </>
  );
}

function SendSplDialog({ onClose, publicKey, accountInfo, onSubmitRef }) {
  const defaultAddressHelperText =
    !accountInfo.parsed.mint || accountInfo.parsed.mint.equals(WRAPPED_SOL_MINT)
      ? 'Enter Solana Address'
      : 'Enter SPL token or Solana address';
  const wallet = useWallet();
  const [sendTransaction, sending] = useSendTransaction();
  const [addressHelperText, setAddressHelperText] = useState(
    defaultAddressHelperText,
  );
  const [passValidation, setPassValidation] = useState();
  const [overrideDestinationCheck, setOverrideDestinationCheck] = useState(
    false,
  );
  const [shouldShowOverride, setShouldShowOverride] = useState();
  let {
    fields,
    destinationAddress,
    transferAmountString,
    validAmount,
    tokenDecimals,
  } = useForm(accountInfo, addressHelperText, passValidation);
  const { mint } = accountInfo.parsed;
  const mintString = mint && mint.toBase58();
  const [isDomainName, setIsDomainName] = useState(false);
  const [domainOwner, setDomainOwner] = useState();

  useEffect(() => {
    (async () => {
      if (destinationAddress.startsWith('@')) {
        const twitterOwner = await resolveTwitterHandle(
          wallet.connection,
          destinationAddress.slice(1),
        );
        if (!twitterOwner) {
          setAddressHelperText(`This Twitter handle is not registered`);
          setPassValidation(undefined);
          setShouldShowOverride(undefined);
          return;
        }
        setIsDomainName(true);
        setDomainOwner(twitterOwner);
      }
      if (destinationAddress.endsWith('.sol')) {
        const name = destinationAddress.slice(0, -4);
        const hasSub = name.split('.').length === 2;

        let domainOwner;

        if (hasSub) {
          const sub = name.split('.')[0];
          const parentKey = await getNameKey(name.split('.')[1]);
          domainOwner = await resolveDomainName(
            wallet.connection,
            sub,
            parentKey,
          );
        } else {
          domainOwner = await resolveDomainName(wallet.connection, name);
        }

        if (!domainOwner) {
          setAddressHelperText(
            `This ${hasSub ? 'subdomain' : 'domain'} name is not registered`,
          );
          setPassValidation(undefined);
          setShouldShowOverride(undefined);
          return;
        }
        setIsDomainName(true);
        setDomainOwner(domainOwner);
      }
      if (!destinationAddress) {
        setAddressHelperText(defaultAddressHelperText);
        setPassValidation(undefined);
        setShouldShowOverride(undefined);
        return;
      }
      try {
        const destinationAccountInfo = await wallet.connection.getAccountInfo(
          new PublicKey(isDomainName ? domainOwner : destinationAddress),
        );
        setShouldShowOverride(false);

        if (destinationAccountInfo.owner.equals(TOKEN_PROGRAM_ID)) {
          const accountInfo = parseTokenAccountData(
            destinationAccountInfo.data,
          );
          if (accountInfo.mint.toBase58() === mintString) {
            setPassValidation(true);
            setAddressHelperText('Address is a valid SPL token address');
          } else {
            setPassValidation(false);
            setAddressHelperText('Destination address mint does not match');
          }
        } else {
          setPassValidation(true);
          setAddressHelperText(
            `Destination is a Solana address: ${
              isDomainName ? domainOwner : destinationAddress
            }`,
          );
        }
      } catch (e) {
        console.log(`Received error validating address ${e}`);
        setAddressHelperText(defaultAddressHelperText);
        setShouldShowOverride(true);
        setPassValidation(undefined);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationAddress, wallet, mintString, isDomainName, domainOwner]);
  useEffect(() => {
    return () => {
      setOverrideDestinationCheck(false);
    };
  }, [setOverrideDestinationCheck]);
  async function makeTransaction() {
    let amount = Math.round(
      parseFloat(transferAmountString) * 10 ** tokenDecimals,
    );
    if (!amount || amount <= 0) {
      throw new Error('Invalid amount');
    }
    return wallet.transferToken(
      publicKey,
      new PublicKey(isDomainName ? domainOwner : destinationAddress),
      amount,
      accountInfo.parsed.mint,
      tokenDecimals,
      null,
      overrideDestinationCheck,
    );
  }

  const disabled = shouldShowOverride
    ? !overrideDestinationCheck || sending || !validAmount
    : sending || !validAmount;

  async function onSubmit() {
    return sendTransaction(makeTransaction(), { onSuccess: onClose });
  }
  onSubmitRef.current = onSubmit;
  return (
    <>
      <DialogContent>{fields}</DialogContent>
      <DialogActions>
        {shouldShowOverride && (
          <div
            style={{
              'align-items': 'center',
              display: 'flex',
              'text-align': 'left',
            }}
          >
            <b>This address has no funds. Are you sure it's correct?</b>
            <Switch
              checked={overrideDestinationCheck}
              onChange={(e) => setOverrideDestinationCheck(e.target.checked)}
              color="primary"
            />
          </div>
        )}
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" color="primary" disabled={disabled}>
          Send
        </Button>
      </DialogActions>
    </>
  );
}
function useForm(accountInfo, addressHelperText, passAddressValidation) {
  const [destinationAddress, setDestinationAddress] = useState('');
  const [transferAmountString, setTransferAmountString] = useState('');
  const { amount: balanceAmount } = accountInfo.parsed;
  const { decimals, symbol: tokenSymbol } = accountInfo.tokenInfo;
  const [tokenDecimals, setTokenDecimals] = useState(0);
  const connection = useConnection();

  useEffect(() => {
    const getTokenDecimals = async (accountInfo) => {
      const accountInfoData = await connection.getParsedAccountInfo(
        accountInfo.parsed.mint,
      );
      const accountValue = accountInfoData.value.data;
      if (accountValue.parsed.info) {
        setTokenDecimals(accountValue.parsed.info.decimals);
      } else {
        setTokenDecimals(0);
      }
    };
    if (Object.keys(accountInfo.tokenInfo).length === 0) {
      getTokenDecimals(accountInfo).catch(() =>
        console.log('failed to fetch token decimals'),
      );
    } else {
      setTokenDecimals(accountInfo.tokenInfo.decimals);
    }
  }, [accountInfo]);

  const parsedAmount = parseFloat(transferAmountString) * 10 ** decimals;
  const validAmount = parsedAmount > 0 && parsedAmount <= balanceAmount;

  const fields = (
    <>
      <TextField
        label="Recipient Address"
        fullWidth
        variant="outlined"
        margin="normal"
        value={destinationAddress}
        onChange={(e) => setDestinationAddress(e.target.value.trim())}
        helperText={addressHelperText}
        id={
          !passAddressValidation && passAddressValidation !== undefined
            ? 'outlined-error-helper-text'
            : undefined
        }
        error={!passAddressValidation && passAddressValidation !== undefined}
      />
      <TextField
        label="Amount"
        fullWidth
        variant="outlined"
        margin="normal"
        type="number"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                onClick={() =>
                  setTransferAmountString(
                    balanceAmountToUserAmount(balanceAmount, tokenDecimals),
                  )
                }
              >
                MAX
              </Button>
              {tokenSymbol ? tokenSymbol : null}
            </InputAdornment>
          ),
          inputProps: {
            step: Math.pow(10, -tokenDecimals),
          },
        }}
        value={transferAmountString}
        onChange={(e) => setTransferAmountString(e.target.value.trim())}
        helperText={
          <span
            onClick={() =>
              setTransferAmountString(
                balanceAmountToUserAmount(balanceAmount, tokenDecimals),
              )
            }
          >
            Max: {balanceAmountToUserAmount(balanceAmount, tokenDecimals)}
          </span>
        }
      />
    </>
  );

  return {
    fields,
    destinationAddress,
    transferAmountString,
    setDestinationAddress,
    validAmount,
    tokenDecimals,
  };
}

function balanceAmountToUserAmount(balanceAmount, decimals) {
  return (balanceAmount / Math.pow(10, decimals)).toFixed(decimals);
}
